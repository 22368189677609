import React from "react";

import { 
    Layout,
    SimplePage
} from "../components";

const TermsPage = () => {

    const blocks = [
        {
            content: 'Anyone can use our website for free. By using our website, you confirm that you agree to comply with these terms of service.',
            title: 'Eligibility',
        },
        {
            content: 'Our website is provided for entertainment purposes only. We are not responsible for any losses or damages that may occur as a result of your use of the website. By using our website, you agree to use it in compliance with all applicable laws and regulations.',
            title: 'Use of Website',
        },
        {
            content: 'To participate in our games and contests, you must sign in using your Steam account. By signing in, you agree to comply with these terms of service. You are responsible for maintaining the confidentiality of your Steam account information and are liable for any activity that occurs on your account.',
            title: 'Steam Account',
        },
        {
            content: 'Our website offers various games and contests for you to participate in. By participating, you confirm that you have read and agree to the terms and conditions of the game or contest.',
            title: 'Games and Contests',
        },
        {
            content: 'We offer cool prizes for our games and contests. Prizes are usually CS:GO skins but may include physical items as well in the future. We reserve the right to change the prizes at any time.',
            title: 'Prizes',
        },
        {
            content: 'We reserve the right to terminate your account at any time if you violate these terms of service or engage in any fraudulent activity.',
            title: 'Termination',
        }
    ];
    const title = "Terms of Service";

    return (
        <Layout 
            title="Terms of Service"
        >
            <SimplePage
                blocks={blocks}
                title={title}
            />
        </Layout>
    )
}

export default TermsPage;